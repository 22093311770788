import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LatestDevices from "../../../components/LatestDevices/latestdevices";
export const _frontmatter = {
  "title": "Welcome"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>ESPHome Device Configuration Repository</h1>
    <p>
  This website is a repository of device configuration templates and setup
  guides for devices running <a href="https://esphome.io">ESPHome</a> firmware.
    </p>
    <p>
  The goal is to document all devices capable of running the firmware along with
  a basic configuration yaml that can be easily copied and uploaded which
  restores the device back to its original functionality. Additionaly, more
  advanced configuration of devices could also be documented.
    </p>
    <LatestDevices mdxType="LatestDevices" />
    <h2>Contributing</h2>
    <p>
  This repository relies on the community to keep it up-to-date and accurate. If
  you identify and errors or find a device that is not added please consider
  contributing.
    </p>
    <ul>
  <li>
    <a href="/adding-devices" className="btn btn-purple mr-2">
      Adding Devices
    </a>
  </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      